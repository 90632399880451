import React from 'react'
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

class TextBox extends React.Component {
     constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            used: false,
        };
    }
    onClick(event) {
        event.stopPropagation();
    }
    handleKeyPress(event) {
        if (event.charCode === 13) { // ENTER
            this.props.onEnter && this.props.onEnter();
        }
    }
    handleKeyDown(event) {
        if (this.props.type === 'number' && event.key === 'e') {
            event.stopPropagation();
            event.preventDefault();
        }
    }
    render() {
        const finalStyle = Object.assign({}, this.props.style);
        let styleClass = '';
        if (!this.props.valid) {
            finalStyle.boxShadow = '#C0564A 0px 0px 0px 2px';
            styleClass = 'warning';
        }
        return <div className={`textbox ${styleClass}`}>
            { this.props.type === 'date' ?
                <DatePicker
                    selected={this.props.text}
                    onBlur={this.props.onBlur}
                    onChange={this.props.onChange}
                    placeholderText={this.props.placeholder}
                    maxDate={new Date()}
                    dateFormat='dd/MM/yyyy'
                />
                :
                <input
                    onBlur={this.props.onBlur}
                    className={this.props.pretext ? 'pretext' : ''}
                    value={this.props.text}
                    maxLength={this.props.maxLength}
                    onChange={this.props.onChange}
                    onClick={this.onClick}
                    onKeyDown={this.handleKeyDown}
                    onKeyPress={this.handleKeyPress}
                    style={finalStyle}
                    type={this.props.type || 'text'}
                    placeholder={this.props.placeholder}>
                </input>
            }
            {!this.props.valid && warningIcon('#C0564A')}
            {this.props.pretext && <div className='input-pretext'>{this.props.pretext}</div>}
            {this.props.label && <div className='input-label'>{this.props.label}{this.props.mandatory && <span className='asterisk'>*</span>}</div>}
        </div>
    }
}

function warningIcon(fill) {
    return <svg style={{
        position: 'absolute',
        right: 10,
        top: 14,
    }} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none'/><path fill={fill} d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>;
}

export default TextBox;